import dayjs from 'dayjs';
import { TTimeFilterValue, TUser } from './types';
import { accessKeys } from './constants';

/* ------- Types ------- */
type TGetUserById = (users: TUser[], userId: string) => TUser | undefined;
type TIsValidMail = (email: string) => RegExpMatchArray | null;
type TFormatDate = (date: string, language: string, options: Intl.DateTimeFormatOptions) => string;
type TCreateFilterTimeRange = (filterValue: TTimeFilterValue) => {
  from: string;
  to: string;
};
type THexToRGBA = (hex: string, alpha?: number) => string;

/* ------- Helpers ------- */
export const getUserById: TGetUserById = (users, userId) => {
  return users.find((user) => user.userId === userId);
};

export const isValidMail: TIsValidMail = (email) => {
  return String(email)
    .toLowerCase()
    .match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/);
};

export const formatDate: TFormatDate = (date, language, options) => {
  return new Date(date).toLocaleDateString(language, options);
};

export const createFilterTimeRange: TCreateFilterTimeRange = (filterValue) => {
  const yesterday = dayjs().date() - 1;
  const now = dayjs().toISOString();

  // return a start and end date based on the filter
  switch (filterValue) {
    case 'today':
      return {
        from: dayjs().startOf('day').toISOString(),
        to: now,
      };
    case 'yesterday':
      return {
        from: dayjs().date(yesterday).startOf('day').toISOString(),
        to: dayjs().date(yesterday).endOf('day').toISOString(),
      };
    case 'currentWeek':
      return {
        from: dayjs().startOf('week').toISOString(),
        to: now,
      };
    case 'currentMonth':
      return {
        from: dayjs().startOf('month').toISOString(),
        to: now,
      };
    case 'currentYear':
      return {
        from: dayjs().startOf('year').toISOString(),
        to: now,
      };
    default:
      return {
        from: filterValue.split(',')[0],
        to: filterValue.split(',')[1],
      };
  }
};

export const machineTypeToImgUrl = (name: string) => {
  return `/images/machines/${name.replace(/\s*([&-])\s*|\s+/g, (m, g1) => g1 || '-')}.png`;
};

export const hexToRGB: THexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const splitCamelCase = (str: string) => str.replace(/([a-z])([A-Z])/g, '$1 $2');

export class User {
  [accessKeys.LAB_ALLOWED]: boolean;
  [accessKeys.NOTIFICATIONS_ALLOWED]: boolean;
  [accessKeys.SERVICE_ALLOWED]: boolean;
  [accessKeys.PARAM_SETTINGS_ALLOWED]: boolean;
  [accessKeys.USER_MANAGEMENT_ALLOWED]: boolean;
  [accessKeys.USER_READ_ALLOWED]: boolean;
  [accessKeys.CREAT_EDIT_TICKETS_ALLOWED]: boolean;
  [accessKeys.APPROVE_ACCEPT_TICKETS_ALLOWED]: boolean;
  [accessKeys.DELETE_MODIFY_DOCUMENTS_ALLOWED]: boolean;

  constructor(permissions) {
    this[accessKeys.LAB_ALLOWED] = permissions?.includes('DataAcc.Read');
    this[accessKeys.NOTIFICATIONS_ALLOWED] = permissions?.includes('DataAcc.Read');
    this[accessKeys.SERVICE_ALLOWED] = permissions?.includes('OPTD.SupView');
    this[accessKeys.PARAM_SETTINGS_ALLOWED] = permissions?.includes('OPTD.ParamAdmin');
    this[accessKeys.USER_MANAGEMENT_ALLOWED] = permissions?.includes('UsrMgmt.Admin');
    this[accessKeys.USER_READ_ALLOWED] = permissions?.includes('UsrMgmt.Read');
    this[accessKeys.CREAT_EDIT_TICKETS_ALLOWED] = permissions?.includes('OPTD.SupAdmin');
    this[accessKeys.APPROVE_ACCEPT_TICKETS_ALLOWED] = permissions?.includes('OPTD.SupAppr');
    this[accessKeys.DELETE_MODIFY_DOCUMENTS_ALLOWED] = permissions?.includes('OPTD.DocAdmin');
  }
}
